export default class PsConfig {

    static versionNo : String = '1.3';

    ///
    /// API Key to connection with Main Backend
    /// This key must equal with key from backend
    ///
    static apiKey: String = "teampsisthebest";

    ///
    /// Live Mode
    /// ------------
    /// If you want to upload to server, please enable below and comment Development below
    ///
    static fixedDomain: String = "https://moovendo.it/adminads"; 
    static domain: String = "https://moovendo.it/adminads"; 

    ///
    /// Development Mode
    /// -----------------
    /// Before upload to server, please comment this 2 lines.
    ///
   //static fixedDomain: String = "https://moovendo.it/adminads";    
   //static domain: String = "http://localhost:3000";

    ///
    /// Firebase Config
    ///
    static firebaseConfig = {
      apiKey: "AIzaSyBBHdafctZItPIOHLYgf3otU5eZ_ubOB88",
      authDomain: "moovendoit.firebaseapp.com",
      databaseURL: "https://moovendoit-default-rtdb.europe-west1.firebasedatabase.app",
      projectId: "moovendoit",
      storageBucket: "moovendoit.appspot.com",
      messagingSenderId: "619478999880",
      appId: "1:619478999880:web:da4059b17084d72f12c97d",
      measurementId: "G-BXG4C4H7FL"
    };
    
    // static firebaseConfig = {
    //     apiKey: "AIzaSyCCbP35UgAsfdnJaQM4TxWDLICYR4gDi28",
    //     authDomain: "flutter-admotors.firebaseapp.com",
    //     databaseURL: "https://flutter-admotors.firebaseio.com",
    //     projectId: "flutter-admotors",
    //     storageBucket: "flutter-admotors.appspot.com",
    //     messagingSenderId: "959099436187",
    //     appId: "1:619478999880:web:da4059b17084d72f12c97d"
    // };
    
    ///
    /// Base URL * Don't update it *
    ///
    static base: String = PsConfig.domain; 
    static baseUrl: String = PsConfig.domain + '/index.php';
    static fixedPath : String = PsConfig.fixedDomain;
    
    /// 
    /// Debug Mode
    ///
    static isDebugMode : Boolean = true;

    /// Is Demo
    ///
    static is_demo : Boolean = true;

    static item_upload_ui1 : Boolean = true;

    static item_upload_ui2 : Boolean = false;

}
